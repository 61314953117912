<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <template v-if="!isReady">
                <v-card class="signup-card">
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Account status</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p class="mb-0">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            Checking status...
                        </p>
                    </v-card-text>
                </v-card>
                <!-- <p class="mt-6 grey--text text--darken-2 text-center">Already have an account? <router-link :to="{ name: 'login', query: { } }">Sign in</router-link></p> -->
            </template>
            <template v-if="showRedirectCard && !isError">
                <v-card class="signup-card">
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Sign up</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p class="mb-0">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            Redirecting to <a :href="redirectURL">{{ redirectLabel }}</a>...
                        </p>
                    </v-card-text>
                </v-card>
                <p class="mt-6 grey--text text--darken-2 text-center">Already have an account? <router-link :to="{ name: 'login', query: { } }">Sign in</router-link></p>
            </template>
            <template v-if="showCreateAccountCard && isReady && !isError">
                <v-card class="signup-card">
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Account status</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="checkAccountStatus">
                            <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <p class="prompt">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            Creating your account...
                        </p>
                        <!-- <v-row no-gutters justify="center" class="mt-4">
                            <v-btn @click="register" elevation="4" color="#3F51B5" class="white--text mx-2"  v-show="step === 'confirm_pseudonym'">Continue</v-btn>
                        </v-row> -->
                    </v-card-text>
                </v-card>
                <!-- <p class="mt-6 grey--text text--darken-2 text-center">Already have an account? <router-link :to="{ name: 'login', query: { } }">Sign in</router-link></p> -->
            </template>
            <template v-if="showSubscriptionCard && isReady && !isError">
                <v-card class="signup-card">
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Account status</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="checkAccountStatus">
                            <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <p class="help"><b>About Unicorn Springs</b></p>
                        <p class="help">
                            We partner with Unicorn Springs for secure payments and account management.
                        </p>
                        <template v-if="isAccountReady">
                            <p class="mb-0">
                                Name: {{ account.name }}
                            </p>
                            <p class="mb-0">
                                Type: {{ account.type }}
                            </p>
                            <template v-if="account.is_open">
                                <p class="mb-0">
                                    Opened on: {{ account.created_on_text }}
                                </p>

                                <template v-if="!account.subscribed">
                                    <p class="mb-0">
                                        You don't have an active VPN subscription.
                                    </p>
                                    <v-row no-gutters justify="center" class="mt-4">
                                        <v-btn @click="subscribeExistingUser" elevation="4" color="#3F51B5" class="white--text mx-2">Subscribe</v-btn>
                                    </v-row>
                            </template>
                                <template v-if="account.subscribed">
                                    <p class="mb-0" v-if="account.service_map.vpn">
                                        VPN subscription is active.
                                    </p>
                                </template>

                                <p class="mb-0" v-if="account.balance > 0">
                                    Account Balance: <span>$</span>{{ account.balance_text }}
                                </p>
                                <p class="mb-0" v-if="account.credit > 0">
                                    Account Credit: <span>$</span>{{ account.credit_text }}
                                </p>

                                <p class="mb-0" v-if="!account.is_payment_due && account.subscribed">
                                    No payment is due at this time.
                                </p>
                                <p class="mb-0" v-if="account.is_payment_due && !account.is_payment_late">
                                    <b>Payment is due.</b>
                                </p>
                                <p class="mb-0" v-if="account.is_payment_due && account.is_payment_late">
                                    <b>Payment is late.</b>
                                </p>
                                <!-- <v-row no-gutters justify="center" class="mt-4">
                                    <v-btn @click="register" elevation="4" color="#3F51B5" class="white--text mx-2"  v-show="step === 'confirm_pseudonym'">Continue</v-btn>
                                </v-row> -->
                            </template>
                            <template v-if="!account.is_open">
                                <p><b>The account is closed.</b></p>
                            </template>
                        </template>
                    </v-card-text>
                </v-card>
                <p class="mt-6 grey--text text--darken-2 text-center"><router-link :to="{ name: 'user-dashboard' }">Continue to dashboard</router-link></p>
            </template>
            <template v-if="serverError">
                <v-card class="signup-card">
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Account status</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p>A server error prevented us from completing this request.</p>
                        <p>Please try again later.</p>
                    </v-card-text>
                </v-card>
                <!-- <p class="mt-6 grey--text text--darken-2 text-center">Already have an account? <router-link :to="{ name: 'login', query: { } }">Sign in</router-link></p> -->
            </template>
        </v-col>
    </v-row>
</template>

<style>
.signup-card p.prompt {
    font-size: 1.3em;
    color: #333333;
}
.signup-card p.help {
    font-size: 1.1em;
    color: #555555;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidProductLookupKey } from '@/sdk/input';

export default {
    components: {
    },
    data: () => ({
        isReady: false,
        showRedirectCard: false,
        isRedirecting: false,
        redirectURL: null,
        redirectLabel: null,
        pseudonym: '',
        showCreateAccountCard: false,
        showSubscriptionCard: false,
        step: null, // input_pseudonym, confirm_pseudonym, register
        product: null,
        isAccountReady: false,
        account: {},
        submitTimestamp: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
    }),
    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        signupURL() {
            // NOTE: the signup website URL should be to our unicorn springs signup page
            const websiteURL = new URL(process.env.VUE_APP_SIGNUP_WEBSITE_URL ?? 'https://cryptium.com');
            if (this.$route.query.pseudonym) {
                websiteURL.searchParams.set('pseudonym', this.$route.query.pseudonym);
            }
            return websiteURL.toString();
        },
        isError() {
            return this.serverError || this.requestError || this.inputError || this.forbiddenError;
        },
    },
    methods: {
        prepareView() {
            if (!this.user.account_id) {
                this.showCreateAccountCard = true;
                this.createAccount();
                return;
            }
            this.showSubscriptionCard = true;
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            // this.redirect = null;
            // this.verificationExpires = null;
        },
        async createAccount() {
            try {
                this.$store.commit('loading', { createAccount: true });
                const response = await this.$client.user(this.user.id).user.createAccount();
                console.log(`createAccount response ${JSON.stringify(response)}`);
                // if (response?.type === 'status' && response?.status?.reserved) {
                //     this.pseudonym = response.status.reserved; // with automatically generated suffix
                //     this.step = 'confirm_pseudonym'; // show a confirmation and let user edit (return to input step)
                // } else {
                //     this.inputError = 'Please select a different pseudonym';
                // }
                if (response?.type === 'status' && response.status.account_id) {
                    await this.$store.dispatch('refresh');
                    this.$nextTick(() => this.prepareView());
                } else {
                    this.serverError = true;
                }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { createAccount: false });
                // this.isViewReady = true;
            }
        },
        async checkAccountStatus() {
            try {
                this.isReady = false;
                this.isAccountReady = false;
                this.$store.commit('loading', { checkAccountStatus: true });
                const response = await this.$client.user(this.user.id).user.checkAccount();
                console.log(`checkAccountStatus response ${JSON.stringify(response)}`); // {"id":"...","ref":"...","name":"test739hV","type":"individual","is_open":true,"service_list":[],"created_on":1724559691879,"is_payment_due":false,"is_payment_late":false,"status_on":1724559691884,"balance":0,"balance_text":"0.00","credit":0,"credit_text":"0.00"}
                if (response?.type === 'status' && typeof response.status.account === 'object') {
                    if (response.status.account.created_on) {
                        response.status.account.created_on_text = new Date(response.status.account.created_on).toLocaleDateString();
                    }
                    if (Array.isArray(response.status.account.service_list)) {
                        const serviceMap = {};
                        response.status.account.service_list.forEach((service) => {
                            // each service is an object like this: { id, is_active, ref, alias, name, type, product_id, product }
                            // where product is an object like { id, name, ref, alias, type } which is usually redundant because it should match what's in the service record
                            if (service.is_active) {
                                serviceMap[service.ref] = true; // for example 'essential-vpn'
                            }
                        });
                        response.status.account.service_map = serviceMap;
                        response.status.account.subscribed = response.status.account.service_list.length > 0;
                    }
                    this.account = response.status.account; // name, type, is_open, service_list, created_on, is_payment_due, is_payment_late, balance, balance_text, credit, credit_text
                    this.isAccountReady = true;
                } else {
                    this.serverError = true;
                }
                // if (response?.type === 'status' && response?.status?.reserved) {
                //     this.pseudonym = response.status.reserved; // with automatically generated suffix
                //     this.step = 'confirm_pseudonym'; // show a confirmation and let user edit (return to input step)
                // } else {
                //     this.inputError = 'Please select a different pseudonym';
                // }
                // if (response?.type === 'status' && response.status.account_id) {
                //     await this.$store.dispatch('refresh');
                //     this.$nextTick(() => this.prepareView());
                // } else {
                //     this.serverError = true;
                // }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { checkAccountStatus: false });
                this.isReady = true;
                this.prepareView();
            }
        },
        async subscribeExistingUser() {
            // sends an existing user who is already registered with unicorn springs to buy a subscription
            try {
                this.$store.commit('loading', { subscribeExistingUser: true });
                const response = await this.$client.user(this.user.id).user.subscribe();
                console.log(`subscribeExistingUser response ${JSON.stringify(response)}`); // {"id":"...","ref":"...","name":"test739hV","type":"individual","is_open":true,"service_list":[],"created_on":1724559691879,"is_payment_due":false,"is_payment_late":false,"status_on":1724559691884,"balance":0,"balance_text":"0.00","credit":0,"credit_text":"0.00"}
                if (response?.type === 'redirect' && typeof response.redirect === 'string') {
                    this.redirect(response.redirect, 'checkout');
                } else {
                    this.serverError = true;
                }
            } catch (err) {
                console.error('subscribeExistingUser failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { checkAccountStatus: false });
            }
        },
        redirect(redirectURL, redirectLabel) {
            if (this.isRedirecting) {
                // redirect already in progress
                return;
            }
            this.showRedirectCard = true;
            this.isRedirecting = true;
            this.redirectURL = redirectURL;
            this.redirectLabel = redirectLabel;
            // show a link after 2 seconds in case auto-redirect fails or takes a long time
            /*
            setTimeout(() => {
                this.showRedirectCard = true;
                this.isRedirecting = false;
            }, 2000);
            */
            if (typeof window.location.push === 'function') {
                window.location.push(this.redirectURL);
            } else {
                window.location.href = this.redirectURL;
            }
        },
        async init() {
            await this.$store.dispatch('refresh');
            this.$nextTick(() => this.checkAccountStatus());
        },
    },
    mounted() {
        if (this.$route.query.product && isValidProductLookupKey(this.$route.query.product)) {
            this.product = this.$route.query.product;
        }

        this.init();
    },
};
</script>
